.projectMini {
  // animation
  transition: 0.3s ease;
  transition-property: border, color;
  // position and layout
  position: relative;
  @include media(">=tablet") {
    border: solid 0.5rem $colorPrimaryB;
  }
  @include media("<tablet") {
    border: solid 0.2rem $colorPrimaryB;
  }

  border-color: $colorPrimaryB;
  // box-model
  @include media(">=LGtablet") {
    height: 15vw;
  }
  @include media("<LGtablet") {
    height: 20vw;
  }
  @include media("<tablet") {
    height: 25vw;
  }
  // background
  cursor: pointer;
  // typography
  color: $colorPrimaryB;
  // hover
  &:hover {
    // animation
    transition: 0.3s ease;
    transition-property: border, color;
    // box model
    @include media(">=tablet") {
      border: solid 0.5rem $colorSecondary;
    }
    @include media("<tablet") {
      border: solid 0.2rem $colorSecondary;
    }
    // children
    h1 {
      color: $colorSecondary;
    }
  }

  // children
  h1 {
    // position and styling
    @include absoluteCenter();
    z-index: 3;
    // animation
    transition: all 0.3s ease;
    // box model
    width: 100%;
    // background
    user-select: none;
    // typography
    font-size: var(--fontSizeLargeParagraph);
    text-align: center;
  }
  .img {
    // position and styling
    @include absoluteCenter();
    // box model
    width: 100%;
    height: 100%;
  }
  .imgCover {
    // position and styling
    z-index: 2;
    @include absoluteCenter();
    // box model
    width: 100%;
    height: 100%;
    // background
    background-color: rgba($color: #000, $alpha: 0.8);
  }
}
