//===variables===//
//colour pallette
$colorPrimaryA: #111b30;
$colorPrimaryB: #fafafa;
$colorSecondary: #72e2ae;

//===mixins===//
// flex box mixin
@mixin flex($justify, $align, $direction) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

// display grid mixin
@mixin grid($col1, $col2, $col3, $gap) {
  display: grid;
  grid-template-columns: $col1 $col2 $col3;
  gap: $gap;
}

// simple mixin to put something in the center of the page
@mixin absoluteCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//===import files===//
// external files (not written by me)
@import "./external/breakpoints";

// global styling
@import "globalStyling";

body {
  // clipping
  @include media(">=desktop") {
    //background-color: rgb(255, 94, 94);
  }
  @include media(">=LGtablet", "<desktop") {
    //background-color: rgb(255, 188, 94);
  }
  @include media(">=tablet", "<LGtablet") {
    //background-color: rgb(193, 255, 94);
  }
  @include media(">=phone", "<tablet") {
    // background-color: rgb(94, 255, 188);
  }
  @include media("<phone") {
    //background-color: rgb(94, 132, 255);
  }
}

// components:
@import "./components/header";
@import "./components/loadingSpinner";
@import "./components/reloadContent";
@import "./components/popup";
@import "./components/projectMini";
@import "./components/gallery";
@import "./components/selectBox";

//pages:
@import "./pages/about";
@import "./pages/landing";
@import "./pages/portfolioPage";
@import "./pages/projectDetail";
@import "./pages/contact";
@import "./pages/notFound";
