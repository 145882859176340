.popup {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 5%;
  padding: 0.5rem 1.5rem;
  transform: translate(-50%);
  width: 30rem;
  height: 10rem;
  border: $colorSecondary solid 0.5rem;
  border-radius: 4rem;
  background-color: $colorPrimaryA;
  @include flex(space-evenly, center, column);
  color: $colorSecondary;
  &__message {
    font-size: 1.5rem;
  }
}
