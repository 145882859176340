.gallery {
  // box model
  width: 100%;
  height: 100%;
  // children
  .imageContainer {
    // position and styling
    @include flex(center, flex-start, row);
    // clipping
    overflow-y: scroll;
    // box model
    max-height: 500px;
    width: 100%;
    // children
    .image {
      // box model
      border-radius: 1rem;
      max-width: 100%;
    }
  }
  .controls {
    // position and styling
    @include flex(center, center, row);
    // box model
    width: 100%;
    margin-top: 0.5rem;
    // children
    .button {
      // position and layout
      position: relative;
      // animation
      transition: all 0.5s ease;
      // box model
      width: 3rem;
      height: 3rem;
      border: solid 0.25rem $colorSecondary;
      // background
      background-color: transparent;
      cursor: pointer;
      &:hover {
        background-color: $colorPrimaryB;
      }
      // children
      p {
        // position and layout
        position: absolute;
        transform: translate(70%, -55%);
        // typograoghy
        color: $colorSecondary;
        font-size: 2rem;
        font-weight: 700;
      }
    }
    .indicators {
      // positon and styling
      @include flex(center, center, row);
      // box-model
      min-width: 40%;
      padding: 0rem 1rem;
      // children
      .indicator {
        // box model
        width: 2.5rem;
        height: 2.5rem;
        margin: 0rem 0.5rem;
        border: solid 0.25rem $colorSecondary;
        border-radius: 1.8rem;
        &__active {
          background-color: #72e2ae98;
        }
        // background
        cursor: pointer;
      }
    }
  }
}
