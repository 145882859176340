.header {
  // position and layout
  position: relative;
  z-index: 1;
  @include flex(space-between, center, row);
  // box model
  min-height: 8vh;
  width: 100%;
  @include media(">=tablet") {
    padding: 0 10vw 0 10vw;
  }
  @include media("<tablet") {
    padding: 0 1vw 0 1vw;
  }
  // children
  .nav {
    // position and layout
    @include flex(flex-end, center, row);
    // display and visbility
    visibility: hidden;
    @include media(">=LGtablet") {
      visibility: visible;
    }
    // box model
    @include media(">=LGtablet") {
      width: 40%;
    }
    @include media("<LGtablet") {
      width: 15%;
    }

    //children
    ul {
      // position and styling
      @include flex(flex-end, center, row);
    }
    a {
      // box model
      margin: 0rem 0rem 0rem 1.5rem;
      // typography
      font-size: var(--fontSizeLargeParagraph);
      font-weight: 700;
      color: $colorSecondary;
      text-decoration: none;
    }
    .active {
      // typography
      text-decoration: underline;
      text-decoration-color: $colorPrimaryB;
    }
  }
  .titleContainer {
    // position and layout
    position: relative;
    //children
    .title {
      // animation
      transition: all 0.5s ease;
      // typography
      font-size: var(--fontSizeMediumHeading);
      font-weight: 700;
      color: $colorSecondary;
      text-decoration: underline;
      text-decoration-color: $colorPrimaryB;
      @include media(">=tablet") {
        text-decoration-thickness: 7px;
      }
      @include media("<tablet") {
        text-decoration-thickness: 4px;
      }
    }
  }
  .burger {
    // position and layout
    position: relative;
    @include flex(space-between, center, column);
    // display and visibility
    visibility: collapse;
    @include media("<LGtablet") {
      visibility: visible;
    }
    // box model
    margin-right: 0.5rem;
    height: 1.4rem;
    @include media(">=LGtablet") {
      height: 2.5rem;
    }
    @include media(">=tablet", "<LGtablet") {
      height: 2rem;
    }
    // background
    cursor: pointer;
    // children
    .bar {
      // display and visibility
      transform-origin: center;
      // animation
      @include media("<LGtablet") {
        transition: all 0.5s ease-in-out;
      }
      &__closed {
        width: var(--burgerBarWidth);
        height: var(--burgerBarHeight);
        background-color: $colorSecondary;
      }
      &__open:nth-last-of-type(1) {
        width: var(--burgerBarWidth);
        height: var(--burgerBarHeight);
        transform: translateY(-0.55rem) rotate(45deg);
        @include media(">=phone", "<tablet") {
          transform: translateY(-0.53rem) rotate(45deg);
        }
        @include media(">=tablet", "<LGtablet") {
          transform: translateY(-0.75rem) rotate(45deg);
        }
        @include media(">=LGtablet") {
          transform: translateY(-0.88rem) rotate(45deg);
        }
        background-color: $colorSecondary;
      }
      &__open:nth-last-of-type(2) {
        width: var(--burgerBarWidth);
        height: var(--burgerBarHeight);
        background-color: $colorPrimaryA;
      }
      &__open:nth-last-of-type(3) {
        width: var(--burgerBarWidth);
        height: var(--burgerBarHeight);
        transform: translateY(0.55rem) rotate(-45deg);
        @include media(">=phone", "<tablet") {
          transform: translateY(0.53rem) rotate(-45deg);
        }
        @include media(">=tablet", "<LGtablet") {
          transform: translateY(0.75rem) rotate(-45deg);
        }
        @include media(">=LGtablet") {
          transform: translateY(0.88rem) rotate(-45deg);
        }
        background-color: $colorSecondary;
      }
    }
  }
  .burgerNav {
    // position and layout
    position: absolute;
    @include flex(center, center, row);
    z-index: -1;
    // box styling
    width: 100%;
    height: 200vh;
    padding-top: 25rem;
    // background
    background-color: $colorPrimaryA;
    // children
    ul {
      // position and layout
      @include flex(space-evenly, center, column);
      // box
      padding-left: 0px;
      height: 15rem;
    }
    a {
      // animation
      transition: all 0.5s ease-in-out;
      // typogrpahy
      font-size: var(--fontSizeHeading);
      font-weight: 700;
      text-decoration: none;
      color: $colorSecondary;
    }
    .active {
      // typography
      color: $colorPrimaryB;
    }
  }
}
