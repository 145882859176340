.contactPage {
  // styling
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
  @include flex(flex-start, center, column);

  // children
  .title {
    font-size: 3rem;
    color: $colorSecondary;
  }
  .errorMessage {
    width: 80%;
    color: $colorSecondary;
    margin-top: 1rem;
    font-size: 2rem;
    text-align: center;
  }
  .form {
    // styling
    margin-top: 2rem;
    width: 80%;
    @include flex(flex-start, center, column);

    // children
    .section {
      // styling
      width: 60%;
      @include flex(flex-start, center, column);
      margin-bottom: 15px;

      // children
      .labelInputWrapper {
        // styling
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;

        // children
        .label {
          font-size: 2rem;
          color: $colorPrimaryB;
          &__desktop {
            text-align: right;
          }
          &__mobile {
            text-align: center;
          }
        }
        .input {
          outline: none;
          border: none;
          background-color: $colorPrimaryB;
          color: $colorPrimaryA;

          &__invalid {
            background-color: $colorSecondary;
          }
          &__desktop {
            font-size: 2rem;
          }
          &__mobile {
            font-size: 1.5rem;
          }
          &::placeholder {
            color: $colorPrimaryA;
            opacity: 60%;
          }
        }
      }
      textarea {
        width: 100%;
        font-size: 1.5rem;
        font-family: "Montserrat", sans-serif;
      }
      .inputError {
        color: $colorSecondary;
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 0.2rem;
      }
      .button {
        cursor: pointer;
        background-color: $colorSecondary;
        border: none;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        user-select: none;
        color: $colorPrimaryA;
        &__desktop {
          font-size: 2.5rem;
        }
        &__mobile {
          font-size: 2rem;
        }
        &:disabled {
          background-color: $colorPrimaryB;
        }
      }
    }
  }
}
