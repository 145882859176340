// get montserrat font, the website's font
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

// remove margin and padding from everything
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// standard font sizing
/*
  "phone": 360px,
  "tablet": 768px,
  "LGtablet": 1120px,
  "desktop": 1366px,
  "LGdesktop": 1920px, 
  "3k": 2500px,
  "4k": 3000px,
*/
// Standardised sizings by screen size
@include media(">4k") {
  :root {
    // font sizing
    --fontSizeLargeHeading: 9rem;
    --fontSizeMediumHeading: 8rem;
    --fontSizeHeading: 3rem;
    --fontSizeLargeParagraph: 5rem;
    --fontSizeMediumParagraph: 4rem;
    --fontSizeParagraph: 3.5rem;
    --fontSizeSmallParagraph: 2.8rem;
    // landing page
    --landingTopMargin: 150px;
  }
}
@include media(">=3k", "<4k") {
  :root {
    // font sizing
    --fontSizeLargeHeading: 7.5rem;
    --fontSizeMediumHeading: 6rem;
    --fontSizeHeading: 3.5rem;
    --fontSizeLargeParagraph: 3.5rem;
    --fontSizeMediumParagraph: 3rem;
    --fontSizeParagraph: 2.5rem;
    --fontSizeSmallParagraph: 2rem;
    // landing page
    --landingTopMargin: 120px;
  }
}
@include media(">=LGdesktop", "<3k") {
  :root {
    // font sizing
    --fontSizeLargeHeading: 7.5rem;
    --fontSizeMediumHeading: 5rem;
    --fontSizeHeading: 3.5rem;
    --fontSizeLargeParagraph: 2.5rem;
    --fontSizeMediumParagraph: 2rem;
    --fontSizeParagraph: 1.6rem;
    --fontSizeSmallParagraph: 1.25rem;
    // landing page
    --landingTopMargin: 120px;
  }
}
@include media(">=desktop", "<LGdesktop") {
  :root {
    // font sizing
    --fontSizeLargeHeading: 6rem;
    --fontSizeMediumHeading: 4.5rem;
    --fontSizeHeading: 3rem;
    --fontSizeLargeParagraph: 2.3rem;
    --fontSizeMediumParagraph: 1.8rem;
    --fontSizeParagraph: 1.5rem;
    --fontSizeSmallParagraph: 1.04rem;
    // landing page
    --landingTopMargin: 80px;
  }
}
@include media(">=LGtablet", "<desktop") {
  :root {
    // font sizing
    --fontSizeLargeHeading: 5rem;
    --fontSizeMediumHeading: 3.6rem;
    --fontSizeHeading: 2.5rem;
    --fontSizeLargeParagraph: 2rem;
    --fontSizeMediumParagraph: 1.75rem;
    --fontSizeParagraph: 1.5rem;
    --fontSizeSmallParagraph: 0.8rem;
    // landing page
    --landingTopMargin: 60px;
    // burger bar
    --burgerBarWidth: 60px;
    --burgerBarHeight: 10px;
  }
}
@include media(">=tablet", "<LGtablet") {
  :root {
    // font sizing
    --fontSizeLargeHeading: 4rem;
    --fontSizeMediumHeading: 3.5rem;
    --fontSizeHeading: 2rem;
    --fontSizeLargeParagraph: 1.75rem;
    --fontSizeMediumParagraph: 1.5rem;
    --fontSizeParagraph: 1.25rem;
    --fontSizeSmallParagraph: 1rem;
    // landing page
    --landingTopMargin: 50px;
    // burger bar
    --burgerBarWidth: 40px;
    --burgerBarHeight: 8px;
  }
}
@include media(">=phone", "<tablet") {
  :root {
    // font sizing
    --fontSizeLargeHeading: 4rem;
    --fontSizeMediumHeading: 2.3rem;
    --fontSizeHeading: 2rem;
    --fontSizeLargeParagraph: 1.3rem;
    --fontSizeMediumParagraph: 1.3rem;
    --fontSizeParagraph: 1.25rem;
    --fontSizeSmallParagraph: 0.95rem;
    // landing page
    --landingTopMargin: 0px;
    // burger bar
    --burgerBarWidth: 30px;
    --burgerBarHeight: 5px;
  }
}
@include media("<=phone") {
  :root {
    // font sizing
    --fontSizeLargeHeading: 3rem;
    --fontSizeMediumHeading: 1.6rem;
    --fontSizeHeading: 1.6rem;
    --fontSizeLargeParagraph: 1.2rem;
    --fontSizeMediumParagraph: 1.2rem;
    --fontSizeParagraph: 1.2rem;
    --fontSizeSmallParagraph: 0.9rem;
    // landing page
    --landingTopMargin: 0px;
    // burger bar
    --burgerBarWidth: 20px;
    --burgerBarHeight: 5px;
  }
}

// standardised font colour
h1,
p {
  color: $colorPrimaryB;
}

// standard body styling
body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: $colorPrimaryA;
}

// remove list styling from ul's
ul {
  list-style: none;
}

// standard button styling
.globalButton {
  @include flex(center, center, row);
  cursor: pointer;
  background-color: $colorSecondary;
  border: none;
  padding: 10px 25px;
  border-radius: 30px;
  font-size: var(--fontSizeMediumParagraph);
  text-decoration: none;
  color: $colorPrimaryA;
  font-weight: 700;
  p {
    user-select: none;
    color: $colorPrimaryA;
  }
}

// scroll bar styling
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $colorPrimaryA;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $colorPrimaryB;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $colorSecondary;
}

// default height and width
#root {
  width: 100%;
  height: 100%;
}

//======MIGHT BIN THIS SHIT======//
// wrappers will be on every page
.allowScroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
.noScroll {
  overflow-y: hidden;
  overflow-x: hidden;
}
.defaultPageWrapper {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}
