// react loader spinner is aparently dead now, and so I adapted a spinner from online,
// so I found this pretty one online which is far nicer then I have the time to do
// source: https://loading.io/css/ 

.loadingSpinner {
    margin-top: 5rem;
    margin-left: 5rem;
    display: inline-block;
    position: relative;
    width: 16rem;
    height: 16rem;
}
.loadingSpinner div {
    transform-origin: 80px 80px;
    animation: loadingSpinner 1.2s linear infinite;
}
.loadingSpinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 6px;
    left: 74px;
    width: 12px;
    height: 36px;
    border-radius: 40%;
    background: #d90429;
}
.loadingSpinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.loadingSpinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.loadingSpinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.loadingSpinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.loadingSpinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.loadingSpinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.loadingSpinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.loadingSpinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.loadingSpinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.loadingSpinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.loadingSpinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.loadingSpinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes loadingSpinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}