.landingPage {
  // position and layout
  @include flex(center, flex-start, row);
  // box model
  width: 100%;
  overflow-y: scroll;
  height: 95vh;
  // children
  wrapper {
    // position and layout
    @include media(">=LGtablet") {
      @include grid(1.5fr, 1fr, 0fr, 2%);
    }
    @include media(">=tablet", "<LGtablet") {
      @include grid(1.2fr, 1fr, 0fr, 1%);
    }
    @include media("<tablet") {
      @include flex(center, center, column);
    }
    // box model
    margin-bottom: 50px;
    @include media(">tablet") {
      width: 80%;
    }
    @include media("<tablet") {
      width: 98%;
    }

    // children
    .content {
      // position and layout
      @include media(">=LGtablet") {
        @include flex(flex-start, flex-start, column);
      }
      @include media("<tablet") {
        @include flex(center, center, column);
      }

      // children
      .title {
        // box model
        margin-top: var(--landingTopMargin);
        // typography
        color: $colorSecondary;
        font-weight: 700;
        font-size: var(--fontSizeLargeHeading);
      }

      .paragraph {
        // box model
        @include media(">=desktop") {
          margin-top: 10px;
        }
        // typography
        color: $colorPrimaryB;
        font-weight: 700;
        font-size: var(--fontSizeLargeParagraph);
        @include media("<tablet") {
          text-align: center;
        }
      }
      .socials {
        // position and layout
        @include flex(flex-start, center, row);
        // box model
        margin-top: 15px;
        width: 180px;
        height: 70px;
        @include media(">3k") {
          height: 120px;
        }
        @include media(">4k") {
          height: 150px;
        }
        // children
        a {
          // position and layout
          @include flex(flex-start, flex-start, row);
          // box model
          margin-right: 30px;
          height: 100%;
          width: 80px;
          @include media(">3k") {
            width: 120px;
          }
          @include media(">4k") {
            width: 150px;
            margin-right: 50px;
          }
          // children
          img {
            filter: invert(98%) sepia(4%) saturate(247%) hue-rotate(222deg) brightness(113%);
            height: 100%;
          }
        }
      }
      .button {
        // box model
        margin-top: 15px;
        margin-bottom: 5px;
        @include media(">4k") {
          width: 700px;
          height: 130px;
          border-radius: 400px;
        }
        @include media(">3k", "<4k") {
          width: 500px;
          height: 90px;
          border-radius: 400px;
        }
        @include media(">phone", "<=3k") {
          width: 300px;
          height: 60px;
        }
        @include media("<=phone") {
          width: 260px;
          height: 60px;
        }
      }
      .imageContainer {
        // position and layout
        @include flex(center, center, row);
        // display and visibility
        @include media(">=tablet") {
          display: none;
        }
        //children
        img {
          // box model
          @include media(">phone") {
            width: 20rem;
          }
          @include media("<=phone") {
            width: 90%;
          }
        }
      }
    }
    .imageSection {
      // position and layout
      @include flex(center, center, row);
      // display and visibility
      @include media("<tablet") {
        display: none;
      }
      //children
      img {
        // box model
        margin-top: var(--landingTopMargin);
        width: 100%;
      }
    }
  }
}
