.selectBox {
  // position and layout
  position: relative;
  // box model
  border: solid 0.2rem $colorPrimaryB;
  @include media(">tablet") {
    width: 17rem;
  }
  // background
  background-color: $colorPrimaryA;
  // typography
  color: $colorSecondary;
  font-size: var(--fontSizeMediumParagraph);
  // children
  .container {
    // position and styling
    @include flex(space-between, center, row);
    // box model
    width: 100%;
    min-width: 10rem;
    // background
    cursor: pointer;
    // children
    .text {
      // box model
      user-select: none;
      margin-left: 1rem;
      // typography
      color: $colorSecondary;
    }
    .arrow {
      // animation
      transform: rotate(-90deg);
      transition: all 0.5s ease;
      &__rotate {
        transform: rotate(-270deg);
      }
      // box model
      margin-right: 1rem;
      user-select: none;
      // typgraphy
      font-size: 2.5rem;
      font-weight: 700;
    }
  }
  .selections {
    // position and layout
    position: absolute;
    z-index: 5;
    // animation
    transition: all 0.5s ease;
    &__visible {
      transition: all 0.5s ease;
      visibility: visible;
      background-color: $colorPrimaryB;
    }
    @include media(">=tablet") {
      transform: translateY(1rem) translateX(-0.2rem);
    }
    @include media("<tablet") {
      transform: translateY(1rem) translateX(-5.1rem);
    }
    // box model
    user-select: none;
    margin-right: 1rem;

    @include media(">=tablet") {
      width: 25rem;
    }
    @include media("<tablet") {
      width: 15rem;
    }
    // background
    visibility: hidden;
    background-color: transparent;
    // children
    :nth-child(1) {
      margin-top: 0.2rem;
    }
    .option {
      // position and visibility
      visibility: hidden;
      @include flex(center, center, row);
      // animation
      transition: all 0.5s ease;
      // box model
      margin-bottom: 0.2rem;
      @include media(">=tablet") {
        height: 2.3rem;
        width: 98.5%;
        transform: translateX(0.2rem);
      }
      @include media("<tablet") {
        width: 97%;
        height: 2rem;
        transform: translateX(0.19rem);
      }
      // backgound
      background-color: transparent;
      // background
      cursor: pointer;
      // typography
      color: transparent;
      &__visible {
        // animation
        transition: all 0.5s ease;
        visibility: visible;
        // background
        background-color: $colorPrimaryA;
        // typography
        color: $colorPrimaryB;
        &:hover {
          color: $colorSecondary;
        }
      }
    }
  }
}
