.aboutPage {
  // display and visibility
  @include flex(center, flex-start, row);
  // box model
  width: 100%;
  overflow-y: scroll;
  height: 95vh;
  // typography
  color: $colorPrimaryB;
  // children
  h1 {
    color: $colorPrimaryB;
    font-size: var(--fontSizeHeading);
  }
  p {
    font-size: var(--fontSizeParagraph);
  }
  .content {
    // box model
    margin-bottom: 90px;
    @include media(">=LGtablet") {
      width: 80%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2.5%;
    }
    @include media("<LGtablet", ">=tablet") {
      margin-top: 10px;
      width: 80%;
      @include flex(center, center, column);
    }
    @include media("<tablet") {
      width: 98%;
    }

    // children
    .box1 {
      .title {
        // position and layout
        @include media(">=LGtablet") {
          @include flex(flex-start, center, row);
        }
        @include media("<LGtablet") {
          @include flex(flex-start, center, row);
        }
        // children
        .socials {
          // position and layout
          @include flex(flex-start, center, row);
          // box-model
          @include media(">=LGtablet") {
            margin-left: 1rem;
            width: 12rem;
            height: 3rem;
          }
          @include media("<LGtablet") {
            margin-left: 1rem;
            width: 10rem;
            height: 2.5rem;
          }
          @include media("<tablet") {
            margin-left: 1rem;
            width: 5rem;
            height: 2rem;
          }
          // children
          a {
            // position and layout
            @include flex(flex-start, flex-start, row);
            // box model
            height: 100%;
            width: 4rem;
            img {
              // clipping
              filter: invert(98%) sepia(4%) saturate(247%) hue-rotate(222deg) brightness(113%);
              // box model
              height: 100%;
            }
          }
        }
      }
      .description {
        // typography
        text-align: justify;
      }
    }
    .box2 {
      // children
      .skillsContainer {
        // position and layout
        @include flex(space-between, center, row);
        flex-wrap: wrap;
        // typography
        list-style-type: square;
        font-size: var(--fontSizeParagraph);
        // children
        li {
          // box model
          margin-left: 40px;
          @include media(">=tablet") {
            width: 180px;
          }
          @include media("<tablet") {
            width: 135px;
          }
          @include media("<phone") {
            width: 110px;
          }
        }
      }
    }
    .box3 {
      // box model
      width: 100%;
      // children

      .selectBoxContainer {
        // position and layout
        @include media(">=LGtablet") {
          @include flex(space-between, center, row);
        }
        @include media("<LGtablet") {
          @include flex(center, center, row);
        }
        // box model
        margin-bottom: 20px;
        font-size: var(--fontSizeParagraph);
        @include media("<LGtablet") {
          p {
            margin-right: 1rem;
          }
        }

        // children
        .selectBoxTitle {
          color: $colorPrimaryB;
        }
      }

      .examWrapper {
        //styling
        @include flex(center, flex-start, row);
        //min-height: 400px;

        //chilren
        .examTable {
          // styling
          color: $colorPrimaryB;
          font-size: var(--fontSizeParagraph);
          @include media(">=LGtablet") {
            width: 100%;
          }
          @include media("<LGtablet") {
            width: 90%;
          }
          @include media("<tablet") {
            width: 98%;
          }

          border: solid 0.25rem $colorPrimaryB;
          text-align: center;

          // children
          .examTitle {
            border-bottom: solid 0.25rem $colorPrimaryB;
            color: $colorSecondary;
          }
        }
      }
    }
    .box4 {
      .achievementTitle {
        font-weight: 300;
        font-size: var(--fontSizeMediumParagraph);
      }
      .achievementDesc {
        font-size: var(--fontSizeParagraph);
      }
    }
  }
}

/*
.about {
  &__container {
    height: 92vh;
    color: $colorPrimaryB;
    width: 100%;
    //background-color: royalblue;
    //color: $colorSecondary;
    h1 {
      color: $colorSecondary;
    }

    &__desktop {
      @include flex(center, flex-start, row);
      h1 {
        font-size: 3rem;
      }
    }
    &__mobile {
      h1 {
        font-size: 2rem;
      }
    }
  }
  &__contentWrapper {
    &__desktop {
      width: 80%;
      @include flex(space-between, flex-start, row);
    }
    &__mobile {
      width: 100%;
      @include flex(center, center, column);
    }
  }
  &__biography {
    &__desktop {
      width: 48%;
    }
    &__mobile {
      width: 98%;
    }
  }
  &__title {
    &__desktop {
      @include flex(flex-start, center, row);
    }
    &__mobile {
      @include flex(center, flex-start, column);
    }
  }
  &__socials {
    @include flex(flex-start, center, row);
    a {
      height: 100%;
      width: 4rem;
      @include flex(flex-start, flex-start, row);
      img {
        filter: invert(98%) sepia(4%) saturate(247%) hue-rotate(222deg) brightness(113%);
        height: 100%;
      }
    }
    &__desktop {
      margin-left: 1rem;
      width: 12rem;
      height: 3rem;
    }
    &__mobile {
      width: 10rem;
      height: 3rem;
    }
  }
  &__skillsWrapper {
    list-style-type: square;
    font-size: 1.75rem;
    margin-bottom: 1rem;
    @include flex(space-between, center, row);
    flex-wrap: wrap;
    li {
      width: 20rem;
    }
  }

  &__description {
    margin-bottom: 1rem;
    &__desktop {
      text-align: justify;
      font-size: 1.75rem;
    }
    &__mobile {
      font-size: 1.5rem;
    }
  }
  &__results {
    &__desktop {
      width: 48%;
      p {
        text-align: justify;
        font-size: 1.8rem;
      }
    }
    &__mobile {
      width: 100%;
      p {
        margin-left: 0.2rem;
        font-size: 1.5rem;
      }
    }
  }
  &__selectBox {
    width: 100%;
    &__desktop {
      @include flex(space-between, center, row);
      p {
        font-size: 2rem;
      }
    }
    &__mobile {
      p {
        font-size: 1.4rem;
        margin-right: 1rem;
      }
      @include flex(center, center, row);
    }
  }
  &__selectBoxTitle {
    color: $colorPrimaryB;
  }
  &__examWrapper {
    width: 100%;
    @include flex(center, center, row);
    margin: 1rem 0rem;
  }
  &__examTable {
    color: $colorPrimaryB;
    font-size: 1.5rem;
    width: 40rem;
    border: solid 0.25rem $colorPrimaryB;
    text-align: center;
  }
  &__examTitle {
    border-bottom: solid 0.25rem $colorPrimaryB;
    color: $colorSecondary;
  }
  &__achievementTitle {
    font-weight: 300;
    font-size: 2rem;
  }
  &__achievementDesc {
    margin-bottom: 1rem;
  }
}

*/
