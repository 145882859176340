/**
*
* Testing
*
**/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/*
  "phone": 360px,
  "tablet": 768px,
  "LGtablet": 1120px,
  "desktop": 1366px,
  "LGdesktop": 1920px, 
  "3k": 2500px,
  "4k": 3000px,
*/
@media (min-width: 3001px) {
  :root {
    --fontSizeLargeHeading: 9rem;
    --fontSizeMediumHeading: 8rem;
    --fontSizeHeading: 3rem;
    --fontSizeLargeParagraph: 5rem;
    --fontSizeMediumParagraph: 4rem;
    --fontSizeParagraph: 3.5rem;
    --fontSizeSmallParagraph: 2.8rem;
    --landingTopMargin: 150px;
  }
}

@media (min-width: 2500px) and (max-width: 2999px) {
  :root {
    --fontSizeLargeHeading: 7.5rem;
    --fontSizeMediumHeading: 6rem;
    --fontSizeHeading: 3.5rem;
    --fontSizeLargeParagraph: 3.5rem;
    --fontSizeMediumParagraph: 3rem;
    --fontSizeParagraph: 2.5rem;
    --fontSizeSmallParagraph: 2rem;
    --landingTopMargin: 120px;
  }
}

@media (min-width: 1920px) and (max-width: 2499px) {
  :root {
    --fontSizeLargeHeading: 7.5rem;
    --fontSizeMediumHeading: 5rem;
    --fontSizeHeading: 3.5rem;
    --fontSizeLargeParagraph: 2.5rem;
    --fontSizeMediumParagraph: 2rem;
    --fontSizeParagraph: 1.6rem;
    --fontSizeSmallParagraph: 1.25rem;
    --landingTopMargin: 120px;
  }
}

@media (min-width: 1366px) and (max-width: 1919px) {
  :root {
    --fontSizeLargeHeading: 6rem;
    --fontSizeMediumHeading: 4.5rem;
    --fontSizeHeading: 3rem;
    --fontSizeLargeParagraph: 2.3rem;
    --fontSizeMediumParagraph: 1.8rem;
    --fontSizeParagraph: 1.5rem;
    --fontSizeSmallParagraph: 1.04rem;
    --landingTopMargin: 80px;
  }
}

@media (min-width: 1120px) and (max-width: 1365px) {
  :root {
    --fontSizeLargeHeading: 5rem;
    --fontSizeMediumHeading: 3.6rem;
    --fontSizeHeading: 2.5rem;
    --fontSizeLargeParagraph: 2rem;
    --fontSizeMediumParagraph: 1.75rem;
    --fontSizeParagraph: 1.5rem;
    --fontSizeSmallParagraph: 0.8rem;
    --landingTopMargin: 60px;
    --burgerBarWidth: 60px;
    --burgerBarHeight: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1119px) {
  :root {
    --fontSizeLargeHeading: 4rem;
    --fontSizeMediumHeading: 3.5rem;
    --fontSizeHeading: 2rem;
    --fontSizeLargeParagraph: 1.75rem;
    --fontSizeMediumParagraph: 1.5rem;
    --fontSizeParagraph: 1.25rem;
    --fontSizeSmallParagraph: 1rem;
    --landingTopMargin: 50px;
    --burgerBarWidth: 40px;
    --burgerBarHeight: 8px;
  }
}

@media (min-width: 360px) and (max-width: 767px) {
  :root {
    --fontSizeLargeHeading: 4rem;
    --fontSizeMediumHeading: 2.3rem;
    --fontSizeHeading: 2rem;
    --fontSizeLargeParagraph: 1.3rem;
    --fontSizeMediumParagraph: 1.3rem;
    --fontSizeParagraph: 1.25rem;
    --fontSizeSmallParagraph: 0.95rem;
    --landingTopMargin: 0px;
    --burgerBarWidth: 30px;
    --burgerBarHeight: 5px;
  }
}

@media (max-width: 360px) {
  :root {
    --fontSizeLargeHeading: 3rem;
    --fontSizeMediumHeading: 1.6rem;
    --fontSizeHeading: 1.6rem;
    --fontSizeLargeParagraph: 1.2rem;
    --fontSizeMediumParagraph: 1.2rem;
    --fontSizeParagraph: 1.2rem;
    --fontSizeSmallParagraph: 0.9rem;
    --landingTopMargin: 0px;
    --burgerBarWidth: 20px;
    --burgerBarHeight: 5px;
  }
}

h1,
p {
  color: #fafafa;
}

body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #111b30;
}

ul {
  list-style: none;
}

.globalButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  cursor: pointer;
  background-color: #72e2ae;
  border: none;
  padding: 10px 25px;
  border-radius: 30px;
  font-size: var(--fontSizeMediumParagraph);
  text-decoration: none;
  color: #111b30;
  font-weight: 700;
}

.globalButton p {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #111b30;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111b30;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fafafa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #72e2ae;
}

#root {
  width: 100%;
  height: 100%;
}

.allowScroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

.noScroll {
  overflow-y: hidden;
  overflow-x: hidden;
}

.defaultPageWrapper {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

.header {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-height: 8vh;
  width: 100%;
}

@media (min-width: 768px) {
  .header {
    padding: 0 10vw 0 10vw;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 0 1vw 0 1vw;
  }
}

.header .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  visibility: hidden;
}

@media (min-width: 1120px) {
  .header .nav {
    visibility: visible;
  }
}

@media (min-width: 1120px) {
  .header .nav {
    width: 40%;
  }
}

@media (max-width: 1119px) {
  .header .nav {
    width: 15%;
  }
}

.header .nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.header .nav a {
  margin: 0rem 0rem 0rem 1.5rem;
  font-size: var(--fontSizeLargeParagraph);
  font-weight: 700;
  color: #72e2ae;
  text-decoration: none;
}

.header .nav .active {
  text-decoration: underline;
  -webkit-text-decoration-color: #fafafa;
          text-decoration-color: #fafafa;
}

.header .titleContainer {
  position: relative;
}

.header .titleContainer .title {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: var(--fontSizeMediumHeading);
  font-weight: 700;
  color: #72e2ae;
  text-decoration: underline;
  -webkit-text-decoration-color: #fafafa;
          text-decoration-color: #fafafa;
}

@media (min-width: 768px) {
  .header .titleContainer .title {
    text-decoration-thickness: 7px;
  }
}

@media (max-width: 767px) {
  .header .titleContainer .title {
    text-decoration-thickness: 4px;
  }
}

.header .burger {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  visibility: collapse;
  margin-right: 0.5rem;
  height: 1.4rem;
  cursor: pointer;
}

@media (max-width: 1119px) {
  .header .burger {
    visibility: visible;
  }
}

@media (min-width: 1120px) {
  .header .burger {
    height: 2.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1119px) {
  .header .burger {
    height: 2rem;
  }
}

.header .burger .bar {
  -webkit-transform-origin: center;
          transform-origin: center;
}

@media (max-width: 1119px) {
  .header .burger .bar {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
}

.header .burger .bar__closed {
  width: var(--burgerBarWidth);
  height: var(--burgerBarHeight);
  background-color: #72e2ae;
}

.header .burger .bar__open:nth-last-of-type(1) {
  width: var(--burgerBarWidth);
  height: var(--burgerBarHeight);
  -webkit-transform: translateY(-0.55rem) rotate(45deg);
          transform: translateY(-0.55rem) rotate(45deg);
  background-color: #72e2ae;
}

@media (min-width: 360px) and (max-width: 767px) {
  .header .burger .bar__open:nth-last-of-type(1) {
    -webkit-transform: translateY(-0.53rem) rotate(45deg);
            transform: translateY(-0.53rem) rotate(45deg);
  }
}

@media (min-width: 768px) and (max-width: 1119px) {
  .header .burger .bar__open:nth-last-of-type(1) {
    -webkit-transform: translateY(-0.75rem) rotate(45deg);
            transform: translateY(-0.75rem) rotate(45deg);
  }
}

@media (min-width: 1120px) {
  .header .burger .bar__open:nth-last-of-type(1) {
    -webkit-transform: translateY(-0.88rem) rotate(45deg);
            transform: translateY(-0.88rem) rotate(45deg);
  }
}

.header .burger .bar__open:nth-last-of-type(2) {
  width: var(--burgerBarWidth);
  height: var(--burgerBarHeight);
  background-color: #111b30;
}

.header .burger .bar__open:nth-last-of-type(3) {
  width: var(--burgerBarWidth);
  height: var(--burgerBarHeight);
  -webkit-transform: translateY(0.55rem) rotate(-45deg);
          transform: translateY(0.55rem) rotate(-45deg);
  background-color: #72e2ae;
}

@media (min-width: 360px) and (max-width: 767px) {
  .header .burger .bar__open:nth-last-of-type(3) {
    -webkit-transform: translateY(0.53rem) rotate(-45deg);
            transform: translateY(0.53rem) rotate(-45deg);
  }
}

@media (min-width: 768px) and (max-width: 1119px) {
  .header .burger .bar__open:nth-last-of-type(3) {
    -webkit-transform: translateY(0.75rem) rotate(-45deg);
            transform: translateY(0.75rem) rotate(-45deg);
  }
}

@media (min-width: 1120px) {
  .header .burger .bar__open:nth-last-of-type(3) {
    -webkit-transform: translateY(0.88rem) rotate(-45deg);
            transform: translateY(0.88rem) rotate(-45deg);
  }
}

.header .burgerNav {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  z-index: -1;
  width: 100%;
  height: 200vh;
  padding-top: 25rem;
  background-color: #111b30;
}

.header .burgerNav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0px;
  height: 15rem;
}

.header .burgerNav a {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  font-size: var(--fontSizeHeading);
  font-weight: 700;
  text-decoration: none;
  color: #72e2ae;
}

.header .burgerNav .active {
  color: #fafafa;
}

.loadingSpinner {
  margin-top: 5rem;
  margin-left: 5rem;
  display: inline-block;
  position: relative;
  width: 16rem;
  height: 16rem;
}

.loadingSpinner div {
  -webkit-transform-origin: 80px 80px;
          transform-origin: 80px 80px;
  -webkit-animation: loadingSpinner 1.2s linear infinite;
          animation: loadingSpinner 1.2s linear infinite;
}

.loadingSpinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 6px;
  left: 74px;
  width: 12px;
  height: 36px;
  border-radius: 40%;
  background: #d90429;
}

.loadingSpinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.loadingSpinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.loadingSpinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.loadingSpinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.loadingSpinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}

.loadingSpinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.loadingSpinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.loadingSpinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.loadingSpinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.loadingSpinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.loadingSpinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

.loadingSpinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

@-webkit-keyframes loadingSpinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes loadingSpinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.reload_content {
  margin-top: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 1.5rem;
  color: #fafafa;
}

.reload_content__button {
  color: #111b30;
  background-color: #72e2ae;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 3rem;
  cursor: pointer;
  padding: 0.8rem 3rem;
  margin-top: 1rem;
  border-radius: 10rem;
  border: none;
  font-weight: 700;
  width: 15rem;
  height: 6rem;
}

.reload_content__button p {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.popup {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 5%;
  padding: 0.5rem 1.5rem;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: 30rem;
  height: 10rem;
  border: #72e2ae solid 0.5rem;
  border-radius: 4rem;
  background-color: #111b30;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #72e2ae;
}

.popup__message {
  font-size: 1.5rem;
}

.projectMini {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-property: border, color;
  transition-property: border, color;
  position: relative;
  border-color: #fafafa;
  cursor: pointer;
  color: #fafafa;
}

@media (min-width: 768px) {
  .projectMini {
    border: solid 0.5rem #fafafa;
  }
}

@media (max-width: 767px) {
  .projectMini {
    border: solid 0.2rem #fafafa;
  }
}

@media (min-width: 1120px) {
  .projectMini {
    height: 15vw;
  }
}

@media (max-width: 1119px) {
  .projectMini {
    height: 20vw;
  }
}

@media (max-width: 767px) {
  .projectMini {
    height: 25vw;
  }
}

.projectMini:hover {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-property: border, color;
  transition-property: border, color;
}

@media (min-width: 768px) {
  .projectMini:hover {
    border: solid 0.5rem #72e2ae;
  }
}

@media (max-width: 767px) {
  .projectMini:hover {
    border: solid 0.2rem #72e2ae;
  }
}

.projectMini:hover h1 {
  color: #72e2ae;
}

.projectMini h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: var(--fontSizeLargeParagraph);
  text-align: center;
}

.projectMini .img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.projectMini .imgCover {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.gallery {
  width: 100%;
  height: 100%;
}

.gallery .imageContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  overflow-y: scroll;
  max-height: 500px;
  width: 100%;
}

.gallery .imageContainer .image {
  border-radius: 1rem;
  max-width: 100%;
}

.gallery .controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  margin-top: 0.5rem;
}

.gallery .controls .button {
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 3rem;
  height: 3rem;
  border: solid 0.25rem #72e2ae;
  background-color: transparent;
  cursor: pointer;
}

.gallery .controls .button:hover {
  background-color: #fafafa;
}

.gallery .controls .button p {
  position: absolute;
  -webkit-transform: translate(70%, -55%);
          transform: translate(70%, -55%);
  color: #72e2ae;
  font-size: 2rem;
  font-weight: 700;
}

.gallery .controls .indicators {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-width: 40%;
  padding: 0rem 1rem;
}

.gallery .controls .indicators .indicator {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0rem 0.5rem;
  border: solid 0.25rem #72e2ae;
  border-radius: 1.8rem;
  cursor: pointer;
}

.gallery .controls .indicators .indicator__active {
  background-color: #72e2ae98;
}

.selectBox {
  position: relative;
  border: solid 0.2rem #fafafa;
  background-color: #111b30;
  color: #72e2ae;
  font-size: var(--fontSizeMediumParagraph);
}

@media (min-width: 769px) {
  .selectBox {
    width: 17rem;
  }
}

.selectBox .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  min-width: 10rem;
  cursor: pointer;
}

.selectBox .container .text {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-left: 1rem;
  color: #72e2ae;
}

.selectBox .container .arrow {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-right: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 2.5rem;
  font-weight: 700;
}

.selectBox .container .arrow__rotate {
  -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg);
}

.selectBox .selections {
  position: absolute;
  z-index: 5;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 1rem;
  visibility: hidden;
  background-color: transparent;
}

.selectBox .selections__visible {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  visibility: visible;
  background-color: #fafafa;
}

@media (min-width: 768px) {
  .selectBox .selections {
    -webkit-transform: translateY(1rem) translateX(-0.2rem);
            transform: translateY(1rem) translateX(-0.2rem);
  }
}

@media (max-width: 767px) {
  .selectBox .selections {
    -webkit-transform: translateY(1rem) translateX(-5.1rem);
            transform: translateY(1rem) translateX(-5.1rem);
  }
}

@media (min-width: 768px) {
  .selectBox .selections {
    width: 25rem;
  }
}

@media (max-width: 767px) {
  .selectBox .selections {
    width: 15rem;
  }
}

.selectBox .selections :nth-child(1) {
  margin-top: 0.2rem;
}

.selectBox .selections .option {
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-bottom: 0.2rem;
  background-color: transparent;
  cursor: pointer;
  color: transparent;
}

@media (min-width: 768px) {
  .selectBox .selections .option {
    height: 2.3rem;
    width: 98.5%;
    -webkit-transform: translateX(0.2rem);
            transform: translateX(0.2rem);
  }
}

@media (max-width: 767px) {
  .selectBox .selections .option {
    width: 97%;
    height: 2rem;
    -webkit-transform: translateX(0.19rem);
            transform: translateX(0.19rem);
  }
}

.selectBox .selections .option__visible {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  visibility: visible;
  background-color: #111b30;
  color: #fafafa;
}

.selectBox .selections .option__visible:hover {
  color: #72e2ae;
}

.aboutPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  overflow-y: scroll;
  height: 95vh;
  color: #fafafa;
}

.aboutPage h1 {
  color: #fafafa;
  font-size: var(--fontSizeHeading);
}

.aboutPage p {
  font-size: var(--fontSizeParagraph);
}

.aboutPage .content {
  margin-bottom: 90px;
}

@media (min-width: 1120px) {
  .aboutPage .content {
    width: 80%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    gap: 2.5%;
  }
}

@media (max-width: 1119px) and (min-width: 768px) {
  .aboutPage .content {
    margin-top: 10px;
    width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 767px) {
  .aboutPage .content {
    width: 98%;
  }
}

@media (min-width: 1120px) {
  .aboutPage .content .box1 .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (max-width: 1119px) {
  .aboutPage .content .box1 .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.aboutPage .content .box1 .title .socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@media (min-width: 1120px) {
  .aboutPage .content .box1 .title .socials {
    margin-left: 1rem;
    width: 12rem;
    height: 3rem;
  }
}

@media (max-width: 1119px) {
  .aboutPage .content .box1 .title .socials {
    margin-left: 1rem;
    width: 10rem;
    height: 2.5rem;
  }
}

@media (max-width: 767px) {
  .aboutPage .content .box1 .title .socials {
    margin-left: 1rem;
    width: 5rem;
    height: 2rem;
  }
}

.aboutPage .content .box1 .title .socials a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 100%;
  width: 4rem;
}

.aboutPage .content .box1 .title .socials a img {
  -webkit-filter: invert(98%) sepia(4%) saturate(247%) hue-rotate(222deg) brightness(113%);
          filter: invert(98%) sepia(4%) saturate(247%) hue-rotate(222deg) brightness(113%);
  height: 100%;
}

.aboutPage .content .box1 .description {
  text-align: justify;
}

.aboutPage .content .box2 .skillsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style-type: square;
  font-size: var(--fontSizeParagraph);
}

.aboutPage .content .box2 .skillsContainer li {
  margin-left: 40px;
}

@media (min-width: 768px) {
  .aboutPage .content .box2 .skillsContainer li {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .aboutPage .content .box2 .skillsContainer li {
    width: 135px;
  }
}

@media (max-width: 359px) {
  .aboutPage .content .box2 .skillsContainer li {
    width: 110px;
  }
}

.aboutPage .content .box3 {
  width: 100%;
}

.aboutPage .content .box3 .selectBoxContainer {
  margin-bottom: 20px;
  font-size: var(--fontSizeParagraph);
}

@media (min-width: 1120px) {
  .aboutPage .content .box3 .selectBoxContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (max-width: 1119px) {
  .aboutPage .content .box3 .selectBoxContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (max-width: 1119px) {
  .aboutPage .content .box3 .selectBoxContainer p {
    margin-right: 1rem;
  }
}

.aboutPage .content .box3 .selectBoxContainer .selectBoxTitle {
  color: #fafafa;
}

.aboutPage .content .box3 .examWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.aboutPage .content .box3 .examWrapper .examTable {
  color: #fafafa;
  font-size: var(--fontSizeParagraph);
  border: solid 0.25rem #fafafa;
  text-align: center;
}

@media (min-width: 1120px) {
  .aboutPage .content .box3 .examWrapper .examTable {
    width: 100%;
  }
}

@media (max-width: 1119px) {
  .aboutPage .content .box3 .examWrapper .examTable {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .aboutPage .content .box3 .examWrapper .examTable {
    width: 98%;
  }
}

.aboutPage .content .box3 .examWrapper .examTable .examTitle {
  border-bottom: solid 0.25rem #fafafa;
  color: #72e2ae;
}

.aboutPage .content .box4 .achievementTitle {
  font-weight: 300;
  font-size: var(--fontSizeMediumParagraph);
}

.aboutPage .content .box4 .achievementDesc {
  font-size: var(--fontSizeParagraph);
}

/*
.about {
  &__container {
    height: 92vh;
    color: $colorPrimaryB;
    width: 100%;
    //background-color: royalblue;
    //color: $colorSecondary;
    h1 {
      color: $colorSecondary;
    }

    &__desktop {
      @include flex(center, flex-start, row);
      h1 {
        font-size: 3rem;
      }
    }
    &__mobile {
      h1 {
        font-size: 2rem;
      }
    }
  }
  &__contentWrapper {
    &__desktop {
      width: 80%;
      @include flex(space-between, flex-start, row);
    }
    &__mobile {
      width: 100%;
      @include flex(center, center, column);
    }
  }
  &__biography {
    &__desktop {
      width: 48%;
    }
    &__mobile {
      width: 98%;
    }
  }
  &__title {
    &__desktop {
      @include flex(flex-start, center, row);
    }
    &__mobile {
      @include flex(center, flex-start, column);
    }
  }
  &__socials {
    @include flex(flex-start, center, row);
    a {
      height: 100%;
      width: 4rem;
      @include flex(flex-start, flex-start, row);
      img {
        filter: invert(98%) sepia(4%) saturate(247%) hue-rotate(222deg) brightness(113%);
        height: 100%;
      }
    }
    &__desktop {
      margin-left: 1rem;
      width: 12rem;
      height: 3rem;
    }
    &__mobile {
      width: 10rem;
      height: 3rem;
    }
  }
  &__skillsWrapper {
    list-style-type: square;
    font-size: 1.75rem;
    margin-bottom: 1rem;
    @include flex(space-between, center, row);
    flex-wrap: wrap;
    li {
      width: 20rem;
    }
  }

  &__description {
    margin-bottom: 1rem;
    &__desktop {
      text-align: justify;
      font-size: 1.75rem;
    }
    &__mobile {
      font-size: 1.5rem;
    }
  }
  &__results {
    &__desktop {
      width: 48%;
      p {
        text-align: justify;
        font-size: 1.8rem;
      }
    }
    &__mobile {
      width: 100%;
      p {
        margin-left: 0.2rem;
        font-size: 1.5rem;
      }
    }
  }
  &__selectBox {
    width: 100%;
    &__desktop {
      @include flex(space-between, center, row);
      p {
        font-size: 2rem;
      }
    }
    &__mobile {
      p {
        font-size: 1.4rem;
        margin-right: 1rem;
      }
      @include flex(center, center, row);
    }
  }
  &__selectBoxTitle {
    color: $colorPrimaryB;
  }
  &__examWrapper {
    width: 100%;
    @include flex(center, center, row);
    margin: 1rem 0rem;
  }
  &__examTable {
    color: $colorPrimaryB;
    font-size: 1.5rem;
    width: 40rem;
    border: solid 0.25rem $colorPrimaryB;
    text-align: center;
  }
  &__examTitle {
    border-bottom: solid 0.25rem $colorPrimaryB;
    color: $colorSecondary;
  }
  &__achievementTitle {
    font-weight: 300;
    font-size: 2rem;
  }
  &__achievementDesc {
    margin-bottom: 1rem;
  }
}

*/
.landingPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  overflow-y: scroll;
  height: 95vh;
}

.landingPage wrapper {
  margin-bottom: 50px;
}

@media (min-width: 1120px) {
  .landingPage wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.5fr 1fr 0fr;
        grid-template-columns: 1.5fr 1fr 0fr;
    gap: 2%;
  }
}

@media (min-width: 768px) and (max-width: 1119px) {
  .landingPage wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.2fr 1fr 0fr;
        grid-template-columns: 1.2fr 1fr 0fr;
    gap: 1%;
  }
}

@media (max-width: 767px) {
  .landingPage wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (min-width: 769px) {
  .landingPage wrapper {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .landingPage wrapper {
    width: 98%;
  }
}

@media (min-width: 1120px) {
  .landingPage wrapper .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 767px) {
  .landingPage wrapper .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.landingPage wrapper .content .title {
  margin-top: var(--landingTopMargin);
  color: #72e2ae;
  font-weight: 700;
  font-size: var(--fontSizeLargeHeading);
}

.landingPage wrapper .content .paragraph {
  color: #fafafa;
  font-weight: 700;
  font-size: var(--fontSizeLargeParagraph);
}

@media (min-width: 1366px) {
  .landingPage wrapper .content .paragraph {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .landingPage wrapper .content .paragraph {
    text-align: center;
  }
}

.landingPage wrapper .content .socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 15px;
  width: 180px;
  height: 70px;
}

@media (min-width: 2501px) {
  .landingPage wrapper .content .socials {
    height: 120px;
  }
}

@media (min-width: 3001px) {
  .landingPage wrapper .content .socials {
    height: 150px;
  }
}

.landingPage wrapper .content .socials a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-right: 30px;
  height: 100%;
  width: 80px;
}

@media (min-width: 2501px) {
  .landingPage wrapper .content .socials a {
    width: 120px;
  }
}

@media (min-width: 3001px) {
  .landingPage wrapper .content .socials a {
    width: 150px;
    margin-right: 50px;
  }
}

.landingPage wrapper .content .socials a img {
  -webkit-filter: invert(98%) sepia(4%) saturate(247%) hue-rotate(222deg) brightness(113%);
          filter: invert(98%) sepia(4%) saturate(247%) hue-rotate(222deg) brightness(113%);
  height: 100%;
}

.landingPage wrapper .content .button {
  margin-top: 15px;
  margin-bottom: 5px;
}

@media (min-width: 3001px) {
  .landingPage wrapper .content .button {
    width: 700px;
    height: 130px;
    border-radius: 400px;
  }
}

@media (min-width: 2501px) and (max-width: 2999px) {
  .landingPage wrapper .content .button {
    width: 500px;
    height: 90px;
    border-radius: 400px;
  }
}

@media (min-width: 361px) and (max-width: 2500px) {
  .landingPage wrapper .content .button {
    width: 300px;
    height: 60px;
  }
}

@media (max-width: 360px) {
  .landingPage wrapper .content .button {
    width: 260px;
    height: 60px;
  }
}

.landingPage wrapper .content .imageContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@media (min-width: 768px) {
  .landingPage wrapper .content .imageContainer {
    display: none;
  }
}

@media (min-width: 361px) {
  .landingPage wrapper .content .imageContainer img {
    width: 20rem;
  }
}

@media (max-width: 360px) {
  .landingPage wrapper .content .imageContainer img {
    width: 90%;
  }
}

.landingPage wrapper .imageSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@media (max-width: 767px) {
  .landingPage wrapper .imageSection {
    display: none;
  }
}

.landingPage wrapper .imageSection img {
  margin-top: var(--landingTopMargin);
  width: 100%;
}

.portfolioPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-height: 92vh;
  font-size: var(--fontSizeLargeParagraph);
}

.portfolioPage .title {
  margin-top: 5px;
  color: #fafafa;
  font-size: var(--fontSizeHeading);
}

@media (min-width: 768px) {
  .portfolioPage .title {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .portfolioPage .title {
    width: 98%;
  }
}

.portfolioPage .intro {
  color: #fafafa;
  text-align: justify;
}

@media (min-width: 768px) {
  .portfolioPage .intro {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .portfolioPage .intro {
    width: 98%;
  }
}

.portfolioPage .selectBoxContainer {
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .portfolioPage .selectBoxContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (max-width: 767px) {
  .portfolioPage .selectBoxContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (min-width: 768px) {
  .portfolioPage .selectBoxContainer {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .portfolioPage .selectBoxContainer {
    width: 98%;
  }
}

.portfolioPage .selectBoxContainer .selectBoxTitle {
  color: #fafafa;
}

@media (min-width: 768px) {
  .portfolioPage .selectBoxContainer .selectBoxTitle {
    margin-right: 2rem;
  }
}

.portfolioPage .minisContainer {
  margin-top: 20px;
  display: -ms-grid;
  display: grid;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .portfolioPage .minisContainer {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .portfolioPage .minisContainer {
    width: 98%;
  }
}

@media (min-width: 1120px) {
  .portfolioPage .minisContainer {
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1119px) {
  .portfolioPage .minisContainer {
    gap: 20px;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .portfolioPage .minisContainer {
    gap: 10px;
  }
}

.projectDetailPage {
  overflow-y: scroll;
  width: 100%;
  height: 95vh;
}

.projectDetailPage wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 60px;
}

.projectDetailPage wrapper .backButton {
  margin: 20px 0px;
}

@media (min-width: 1120px) {
  .projectDetailPage wrapper .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (max-width: 1119px) {
  .projectDetailPage wrapper .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (min-width: 768px) {
  .projectDetailPage wrapper .header {
    margin: 40px 0px 20px 0px;
  }
}

@media (max-width: 767px) {
  .projectDetailPage wrapper .header {
    width: 98%;
  }
}

.projectDetailPage wrapper .header .title {
  color: #fafafa;
  font-size: var(--fontSizeHeading);
}

@media (max-width: 767px) {
  .projectDetailPage wrapper .header .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (max-width: 767px) {
  .projectDetailPage wrapper .header .title {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .projectDetailPage wrapper .header .title {
    text-align: center;
  }
}

.projectDetailPage wrapper .header .details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #fafafa;
  font-size: var(--fontSizeSmallParagraph);
}

@media (max-width: 1119px) {
  .projectDetailPage wrapper .header .details {
    text-align: center;
  }
}

.projectDetailPage wrapper .header .details .detail {
  width: 100%;
}

@media (min-width: 768px) {
  .projectDetailPage wrapper .main {
    display: -ms-grid;
    display: grid;
    gap: 1%;
    -ms-grid-columns: 5fr 4fr;
        grid-template-columns: 5fr 4fr;
  }
}

@media (max-width: 767px) {
  .projectDetailPage wrapper .main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (min-width: 768px) {
  .projectDetailPage wrapper .main {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .projectDetailPage wrapper .main {
    width: 98%;
  }
}

.projectDetailPage wrapper .main .descriptionContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.projectDetailPage wrapper .main .descriptionContainer .description {
  color: #fafafa;
  text-align: justify;
  font-size: var(--fontSizeMediumParagraph);
}

.projectDetailPage wrapper .main .descriptionContainer .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 15px;
  width: 100%;
}

@media (max-width: 1365px) {
  .projectDetailPage wrapper .main .descriptionContainer .buttons .runProjectButton {
    display: none;
  }
}

.contactPage {
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contactPage .title {
  font-size: 3rem;
  color: #72e2ae;
}

.contactPage .errorMessage {
  width: 80%;
  color: #72e2ae;
  margin-top: 1rem;
  font-size: 2rem;
  text-align: center;
}

.contactPage .form {
  margin-top: 2rem;
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contactPage .form .section {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 15px;
}

.contactPage .form .section .labelInputWrapper {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.contactPage .form .section .labelInputWrapper .label {
  font-size: 2rem;
  color: #fafafa;
}

.contactPage .form .section .labelInputWrapper .label__desktop {
  text-align: right;
}

.contactPage .form .section .labelInputWrapper .label__mobile {
  text-align: center;
}

.contactPage .form .section .labelInputWrapper .input {
  outline: none;
  border: none;
  background-color: #fafafa;
  color: #111b30;
}

.contactPage .form .section .labelInputWrapper .input__invalid {
  background-color: #72e2ae;
}

.contactPage .form .section .labelInputWrapper .input__desktop {
  font-size: 2rem;
}

.contactPage .form .section .labelInputWrapper .input__mobile {
  font-size: 1.5rem;
}

.contactPage .form .section .labelInputWrapper .input::-webkit-input-placeholder {
  color: #111b30;
  opacity: 60%;
}

.contactPage .form .section .labelInputWrapper .input:-ms-input-placeholder {
  color: #111b30;
  opacity: 60%;
}

.contactPage .form .section .labelInputWrapper .input::-ms-input-placeholder {
  color: #111b30;
  opacity: 60%;
}

.contactPage .form .section .labelInputWrapper .input::placeholder {
  color: #111b30;
  opacity: 60%;
}

.contactPage .form .section textarea {
  width: 100%;
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
}

.contactPage .form .section .inputError {
  color: #72e2ae;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0.2rem;
}

.contactPage .form .section .button {
  cursor: pointer;
  background-color: #72e2ae;
  border: none;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #111b30;
}

.contactPage .form .section .button__desktop {
  font-size: 2.5rem;
}

.contactPage .form .section .button__mobile {
  font-size: 2rem;
}

.contactPage .form .section .button:disabled {
  background-color: #fafafa;
}

.notFoundPage {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.notFoundPage h1 {
  margin-top: 24px;
  font-size: 3rem;
}

.notFoundPage p {
  margin-top: 12px;
  font-size: 2rem;
}

.notFoundPage button {
  margin-top: 10px;
}
