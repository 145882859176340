.portfolioPage {
  // position and styling
  @include flex(flex-start, center, column);
  // box model
  width: 100%;
  max-height: 92vh;
  // typography
  font-size: var(--fontSizeLargeParagraph);
  // children
  .title {
    // box model
    @include media(">=tablet") {
      width: 80%;
    }
    @include media("<tablet") {
      width: 98%;
    }
    margin-top: 5px;
    // typograophy
    color: $colorPrimaryB;
    font-size: var(--fontSizeHeading);
  }
  .intro {
    // box model
    @include media(">=tablet") {
      width: 80%;
    }
    @include media("<tablet") {
      width: 98%;
    }
    // typgraphy
    color: $colorPrimaryB;
    text-align: justify;
  }
  .selectBoxContainer {
    // position and layout
    @include media(">=tablet") {
      @include flex(flex-start, center, row);
    }
    @include media("<tablet") {
      @include flex(space-between, center, row);
    }
    // box model
    margin-top: 1rem;
    @include media(">=tablet") {
      width: 80%;
    }
    @include media("<tablet") {
      width: 98%;
    }
    // children
    .selectBoxTitle {
      // box model
      @include media(">=tablet") {
        margin-right: 2rem;
      }
      // typography
      color: $colorPrimaryB;
    }
  }
  .minisContainer {
    margin-top: 20px;
    @include media(">=tablet") {
      width: 80%;
    }
    @include media("<tablet") {
      width: 98%;
    }

    display: grid;
    @include media(">=LGtablet") {
      gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include media("<LGtablet") {
      gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
    @include media("<tablet") {
      gap: 10px;
    }
    margin-bottom: 30px;
  }
}
